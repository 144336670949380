import HexaCentralWrapper from "@jordibosch20/software_tools_package/dist/pages/HexCentralWrapper.component.js"
import styles from "./App.module.css";
import { getHexaString } from "@jordibosch20/software_tools_package/dist/utils/hexa.js"
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <HexaCentralWrapper title={"Hexadecimal Encoder / Decoder"} f={getHexaString}></HexaCentralWrapper>
      </div>
      <div className={styles.text}>
          <h1>Text/Files to hexadecimal converter online: Your tool for encoding and decoding</h1>
          <p>In the digital age, data manipulation and storage have become paramount. Whether you're a developer, a student, or just someone fascinated by hexadecimal code, understanding and utilizing different encoding formats can significantly impact your projects and learning. 
            That's where our Online Text to hexadecimal converter steps in, offering a seamless solution for encoding and decoding text and files in UTF-8, UTF-16, UTF-32, ISO-8859 or another character set. This post will guide you through the benefits and applications of our tool, 
            ensuring you can leverage its full potential.</p>
          <h3>Encoding and Decoding Made Easy</h3>
          <p>Encoding files into hexadecimal and decoding them back to their original format is essential for data transmission and storage. Our converter supports various encoding formats, including UTF8, UTF16, and UTF32, catering to different needs and ensuring compatibility 
            across diverse platforms and devices.</p>
          <h2>How Our Converter Works</h2>
          <ol>
          <li><strong>Text to hexadecimal Conversion</strong>: Enter your text, and our tool converts it into hexadecimal code, allowing you to understand how data is represented digitally.</li>
          <li><strong>File Upload for Encoding/Decoding</strong>: Upload files, and choose your desired encoding format. Our tool handles the rest, providing you with the encoded hexadecimal data of the decoded file. We introduce it in 
          a friendly format to be able to quickly separate differetn bytes (with the 0x prefix)</li>
          </ol>
          <h3>Practical Applications</h3>
          <ul>
          <li><strong>Software Development</strong>: Debugging or developing applications that interact with low-level data representation.</li>
          <li><strong>Education</strong>: Learning how data is stored and manipulated at the hexadecimal level.</li>
          <li><strong>Data Analysis</strong>: Analyzing and converting data for various computational needs.</li>
          </ul>
          <h2>Why Choose Our Online Converter?</h2>
          <ul>
          <li><strong>User-Friendly Interface</strong>: Our tool is designed for ease of use, requiring no prior knowledge of data encoding.</li>
          <li><strong>Privacy</strong>: We prioritize your privacy and security. Uploaded files and entered text are processed on your device memory without being stored in any of our server, thus we never see your text/files. You can be sure, there is no data leakage</li>
          </ul>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;